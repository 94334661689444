<template>
  <div>
    <div class="flexRight">
      <p><router-link to="/service" class="link">在线客服</router-link></p>
      <p><a class="link" @click="()=>{
            contentHtml = detail.rule
            contentHtmlModal = true
          }">活动规则</a></p>
    </div>
    <div class="detail">
      <div class="gimg">
        <img :src="detail.rewardPrize" width="100%" class="img"/>
        <img src="https://kaiyunhezi.oss-cn-shanghai.aliyuncs.com/WechatIMG918.jpg" width="100%" class="f"/>
      </div>
      <div class="box">


        <div class="tag">
          <van-tag type="primary" color="#ed5f32" text-color="#fff" size="medium">享开盒子</van-tag>
          <van-tag v-for="(item,key) in labeList" :key="key" plain type="primary" color="#ed5f32" text-color="#ed5f32" size="medium">{{item}}</van-tag>
        </div>
        <div class="title">{{detail.prizeGoodsName}}</div>

        <van-row type="flex" justify="space-between" align="bottom" class="desc">
          <van-col class="price">开盒价 ¥<span>{{detail.price}}</span></van-col>
          <van-col>
            <van-row type="flex" :gutter="10">
              <van-col class="flicon">限时福利</van-col>
              <van-col class="count-down">
                <van-count-down :time="time">
                  <template #default="timeData">
                    <span class="colon">还剩</span>
                    <span class="block">{{ timeData.hours }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.minutes }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.seconds }}</span>
                  </template>
                </van-count-down>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
        <van-cell-group class="mobile">
          <van-field label="开盒手机号：" v-model="json.phone" type="tel" :border="false" ref="phone" placeholder="请输入您的手机号码" maxlength="11"/>
        </van-cell-group>

      </div>
      <div class="box1">


        <van-row type="flex" justify="space-between" align="center" class="order_pay">
          <van-col class="price">¥<span>{{detail.price}}</span>开盒价</van-col>
          <van-col class="btn" @click="payorder">提交订单</van-col>
        </van-row>

        <van-row type="flex" justify="space-between" align="center" class="info_text">
          <van-col class="t1"><van-icon name="warning" />名额有限 立即抢购</van-col>
          <van-col class="t2">*注意：填错手机号可能导致订单无法发货</van-col>
        </van-row>





      </div>
      <div class="comment">
        <div class="lm_title">宝贝评价（{{commentList.length}}）</div>
        <div class="tag">
          <van-tag type="primary" color="#f0f5ec" text-color="#7cab58" size="medium">正品(177)</van-tag>
          <van-tag type="primary" color="#f9efef" text-color="#c96163" size="medium">性价比合适(177)</van-tag>
          <van-tag type="primary" color="#f9efef" text-color="#c96163" size="medium">发货快(177)</van-tag>
        </div>

        <div class="list">
          <div class="item" v-for="(item,key) in commentList" :key="key">
            <img :src="(item.avatar=='' || item.avatar==null)?noheadimg:item.avatar" class="headimg"/>
            <div class="info">
              <div class="top">
                <div class="u_info">
                  <div class="nickName">{{item.name}}</div>
                  <div class="time">{{item.commentTime}}</div>
                </div>
                <div class="r">
                  <div class="status">已确认收货</div>
                  <div class="star"><van-rate color="#ed6330" :value="5" :count="5" :size="12" readonly/></div>
                </div>
              </div>
              <div class="content">{{item.content}}</div>
            </div>
          </div>
        </div>

      </div>

      <div class="comment_pic">
        <div class="lm_title">精选晒图</div>
        <div class="list">
          <img v-for="(img,key) in imgs" :key="key" :src="img"/>
        </div>
      </div>


      <div class="prize">
        <div class="lm_title">该盲盒内商品</div>
        <div class="sale"><img :src="oss+'box_salc.png?v1'" width="100%"/></div>
        <div class="glist">
          <van-row type="flex">
            <van-col class="qualifications" @click="onImagePreview(['https://kaiyunhezi.oss-cn-shanghai.aliyuncs.com/WechatIMG589.jpg'])">
              <img src="https://kaiyunhezi.oss-cn-shanghai.aliyuncs.com/WechatIMG589.jpg"/>
            </van-col>
            <van-col v-for="item in goodList" :key="item.id">
              <img :src="item.image" width="100%"/>

              <van-tag type="primary" :color="glabelsColor[item.tag]" text-color="#fff" size="small">{{glabels[item.tag]}}</van-tag>
              <div class="name">{{item.goods_name}}</div>
              <div class="price">¥{{item.coin_price}}</div>
            </van-col>
          </van-row>
        </div>
      </div>

      <div class="shuoming">
        <p>*盲盒抽取具有随机性，开出商品以实际为准！</p>
        <p>*（广告主根据自身要求，写其他说明事项）</p>
        <p>*购买前请您认真仔细阅读<a @click="()=>{
            contentHtml = detail.user_agreement
            contentHtmlModal = true
          }" class="font-color-blue">《用户协议》</a><a @click="()=>{
            contentHtml = detail.rule
            contentHtmlModal = true
          }" class="font-color-blue">《活动规则》</a><a @click="()=>{
            contentHtml = detail.privacy_policy
            contentHtmlModal = true
          }" class="font-color-blue">《隐私协议》</a></p>
      </div>

      <foot/>
    </div>

    <van-popup value position="bottom" :overlay="false" :lock-scroll="false" class="footPayBtn" v-if="detail.box_id" :z-index="9">
      <van-row type="flex" align="center">
        <van-col span="11" class="price">开盒价：<em>¥ </em><span>{{detail.price}}</span></van-col>
        <van-col span="13" class="right" @click="payorder()">
          <div class="btn">提交订单</div>
          <div class="countDown">
            <van-count-down :time="time">
              <template #default="timeData">
                <span class="block">{{ timeData.hours }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.minutes }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds }}</span>
                <span>后福利失效</span>
              </template>
            </van-count-down>
          </div>
        </van-col>
      </van-row>
    </van-popup>


    <jp :visible="jpModal" :goodList="goodList" @onClose="()=>{jpModal = false}" />
    <van-popup v-if="contentHtmlModal" v-model="contentHtmlModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="contentPop">
      <div class="html" v-html="contentHtml"></div>
      <div class="close"><van-button type="primary" round color="#2d8cf0" style="width:60%" @click="()=>{contentHtmlModal = false}">确定</van-button></div>
    </van-popup>
  </div>
</template>
<script>
  import { ImagePreview } from "vant"
  import {getordernum} from '@/utils/utils'
  import foot from '@/components/footer'
  import jp from './components/jp'
  import {GetPayConfig,GetBoxInfo,CreateOrder,GetOrderStatus} from './api/api'
  export default {
    name:'box4',
    components: {foot,jp},
    data () {
      return {
        type:'taw',
        sessionKey:'box1_order',
        time:15 * 60 * 60 * 1000,
        labeList:['最高抽','国行正品 旗舰店发货'],
        imgs:[],
        glabels:{
          legend:'传说',
          supreme:'史诗',
          rare:'稀有',
          normal:'普通',
        },
        glabelsColor:{
          legend:'#aa76ff',
          supreme:'#fe514f',
          rare:'#fab230',
          normal:'#5eaeff',
        },
        payList:[],
        goodList:[],
        commentList:[],
        detail:{},
        json:{
          tag:'taw',
          pay_way:0,
          order_sn:'',
          phone:'',
          url:location.href,
          box_id:''
        },
        a_oId:'',
        paysuccess:0,
        paytime:3 * 1000,
        count:0,
        maxcount:10,
        timer:null,
        contentHtmlModal:false,
        contentHtml:'',
        isread:true,
        isback:false,
        jpModal:false
      }
    },
    created(){
      const {a_oId,phone} = this.$route.query
      this.json.order_sn=a_oId==undefined?undefined:a_oId
      this.json={
        ...this.json,
        ...this.$route.query,
        phone:(phone==undefined?'':atob(phone))
      }

    },
    mounted(){
      window.addEventListener(
        "pageshow", ()=> {
          if(this.isback){
            location.reload()
            return false
          }
        },
        false
      )
      setTimeout(()=>{
        if(sessionStorage.getItem(this.sessionKey) && this.json.order_sn == ''){
          let order_info = JSON.parse(sessionStorage.getItem(this.sessionKey))
          this.json={
            ...this.json,
            ...order_info
          }
        }
        if(this.json.order_sn != '' ){
          this.isread = true
          setTimeout(()=>{
            this.findOrderStatus()
          },15000)

        }
      },1000)
      this.findpayConfig()
    },
    computed:{
    },
    watch:{
      'json.phone':{
        handler:function(value){
          if(value.length == 11){
            if(!/^1[3456789]\d{9}$/.test(value)) {
              this.$toast('请输入正确的手机号码')
              this.$refs.phone.focus()
            }else{
              this.isread = true
              this.$refs.phone.blur()
            }
          }
        },
        deep:true
      }
    },
    methods:{
      findpayConfig(){
        GetPayConfig({type:this.json.tag}).then(result => {
          if(result.data.code==1){
            const {payConfig,box_id,commentList} = result.data.data
            this.payList=payConfig
            this.commentList = commentList
            this.json.pay_way=this.payList[0].payWay
            this.detail = result.data.data
            this.json.box_id=box_id

            this.findDetail(box_id)
            this.getimgs(commentList)
            const {app_download_url,kefu_url} = this.detail
            sessionStorage.setItem('payConfig',JSON.stringify({app_download_url,kefu_url}))
          }else{
            this.$toast.fail(result.data.msg)
          }
        })

      },
      findDetail(box_id){
        GetBoxInfo({id:box_id}).then(result => {
          if(result.data.code==1){
            const {data} = result.data
            this.goodList=data
          }else{
            this.$toast.fail(result.data.msg)
          }
        })

      },
      getimgs(res){
        let _imgs = res.map(item=>{
          return item.commentImg
        })
        _imgs = _imgs.toString()
        this.imgs = _imgs.split(',')

      },
      payorder(){
        if(!this.isread){
          this.$toast('请阅读隐私协议与用户协议')
          return false
        }
        if(this.json.phone==''){
          this.$toast('请输入您的开盒手机号')
          this.$refs.phone.focus()
          return false
        }else if(!/^1[3456789]\d{9}$/.test(this.json.phone)){
          this.$toast('请输入正确的手机号码')
          this.$refs.phone.focus()
          return false
        }
        this.$toast.loading({
          message: '正在提交订单...',
          forbidClick: true,
        })
        let pay=this.payList.find(item=>item.payWay == this.json.pay_way)
        const {pwyAccount} = pay
        let order_sn = this.json.order_sn==''?getordernum(6):this.json.order_sn
        console.log(order_sn)
        this.json={
          ...this.json,
          account:pwyAccount,
          order_sn:order_sn
        }
        this.getCreateOrder(this.json)
      },
      getCreateOrder(res){

        CreateOrder(res).then(result => {
          if(result.data.code==1){
            sessionStorage.setItem(this.sessionKey,JSON.stringify(res))
            let pay_way = res.pay_way
            const data = result.data.data
            console.log(data)
            if(pay_way==14){
              setTimeout(()=>{
              this.findOrderStatus()
            },3000)
            this.jumpUrl(result.data)
              // this.payWeChat(data)
            }else if(pay_way==16){
              this.Alipay(result.data.data)
            }

          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      Alipay(res){
        const div = document.createElement("formdiv")
        div.innerHTML = res
        document.body.appendChild(div)
        console.log(document.forms['alipay_submit'])
        document.forms["alipay_submit"].setAttribute("target", "_self")
        document.forms["alipay_submit"].submit()
        div.remove()
      },
      findOrderStatus(){
        console.log(this.json)
        if(this.json.order_sn){
          GetOrderStatus({order_sn:this.json.order_sn}).then(result => {
          const {code} = result.data
          // let code = 1
          this.paysuccess = code
          if(code==1){
            this.$toast.success('支付成功')
            this.clearTime()
            setTimeout(()=>{
              this.finishPay()
            },500)
          }else{
            this.$dialog.confirm({
              title: '确认支付情况',
              confirmButtonText:'已完成支付',
              cancelButtonText:'重新支付',
              closeOnClickOverlay:true
            }).then(() => {//已支付完成检测
              this.findOrderStatus()
            }).catch((res) => {
              if(res == 'overlay'){//关闭弹层
                this.clearTime()
              }else{//重新支付
                this.getCreateOrder(this.json)
              }
            })
            if(this.timer == null && this.count<this.maxcount){
              this.getTimerStatus()
            }
          }
        })
        }

      },
      getTimerStatus(){
        this.timer = setInterval(() => {
          if(this.count>=this.maxcount){
            this.clearTime()
            return false
          }
          this.count=this.count+1
          this.findOrderStatus()
        },1*1000)
      },
      clearTime(){
        clearInterval(this.timer)
        sessionStorage.removeItem(this.sessionKey)
      },
      finishPay(){
        this.jumpUrl('https://api.cyrai.cn/xd/paysuccess.html?orderNo='+this.json.order_sn)
      },
      onSubmitPay(status){
        switch (status) {
          case 1:
            this.findOrderStatus()
            break;
          case 0:
            this.findOrderStatus()
            break;
        }
      },
      jumpUrl(url){
        this.isback = true
        setTimeout(()=>{
          location.href=url
        },300)
      },
      onImagePreview(imgs){
        ImagePreview({
          images:imgs,
          closeable: true,
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  @import "./css/index4.less";
</style>
